<template>
  <section class="intro-x my-5">
    <div class="w-full mb-5">
      <h1 class="capitalize text-xl font-bold">Detalle del pedido </h1>
    </div>
    <div class="w-full flex items-center">
      <back-button />
      <button
      v-if="getOrder.stateLast === 1 || getOrder.stateLast === 3"
      class="btn btn-primary ml-2 capitalize"
      @click="onSubmit(1)"
      >
        Guardar
      </button>
      <button
      v-if="getOrder.stateLast === 1"
      class="btn btn-primary ml-2 capitalize"
      @click="onSubmit(2)"
      >
        Solicitar a proveedor
      </button>
      <button
      v-if="getOrder.stateLast === 2"
      class="btn btn-primary ml-2 capitalize"
      @click="onSubmit(3)"
      >
        confirmar Recibido de proveedor
      </button>
      <button
      v-if="getOrder.stateLast === 3"
      class="btn btn-primary ml-2 capitalize"
      @click="onSubmit(4)"
      >
        transferir a inventario
      </button>
      <button
      v-if="getOrder.stateLast < 5"
      class="btn btn-primary ml-2 capitalize"
      @click="dialogAnularPedido=true"
      >
        Anular Pedido
      </button>
    </div>
    <div class="w-full my-3" v-if="getOrder.stateLast === 2">
      <span for="observations">Número de correspondencia </span>
      <input v-model="getOrder.correspondenceId" type="text" class="form-control" >
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-5">
      <div class="w-full box p-4 text-center">
        <h1 v-if="getOrder.stateLast===1" class="font-bold text-xl">Abierto</h1>
        <h1 v-if="getOrder.stateLast===2" class="font-bold text-xl">Solicitado a proveedor</h1>
        <h1 v-if="getOrder.stateLast===3" class="font-bold text-xl">Recibido de proveedor</h1>
        <h1 v-if="getOrder.stateLast===4" class="font-bold text-xl">Devuelto a proveedor</h1>
        <h1 v-if="getOrder.stateLast===5" class="font-bold text-xl">Consolidado a inventario</h1>
        <h1 v-if="getOrder.stateLast===6" class="font-bold text-xl">Anulado</h1>
        <p class="text-gray-600">Estado</p>
      </div>
      <div class="box p-4 text-center">
        <h1 class="font-bold text-xl"> {{getOrder.consecutive}} </h1>
        <p class="text-gray-600">Consecutivo</p>
      </div>
      <div class="box p-4 text-center">
        <h1 class="font-bold text-xl"> {{getOrder.details.length}} </h1>
        <p class="text-gray-600 capitalize text-sm">Cantidad productos</p>
      </div><div class="box p-4 text-center">
        <h1 class="font-bold text-xl"> {{$h.formatDate(getOrder.createdAt,'DD MMM YYYY')}} </h1>
        <p class="text-gray-600 capitalize text-sm">Fecha Solicitud</p>
      </div>
    </div>
    <div class="w-full my-5">
      <div class="w-full rounded-sm overflow-hidden">
        <DataTable
        :value="getDetails"
        :scrollable="true"
        class="p-datatable-sm"
        scrollHeight="400px"
        >
          <Column header="Id" field="id" sortable headerStyle="width: 50px;" />
          <Column header="Producto" sortable field="articlePresentacion.article.name"  headerStyle="min-witdh: 240px;" />
          <Column header="Valor" sortable field="amount" headerStyle="width: 150px;" />
          <Column header="Total" sortable field="total" headerStyle="width: 150px;" />
          <Column
          header="Cantidad Solicitada"
          sortable
          field="requestQuantity"
          headerStyle="width: 150px;"
          >
            <template #body="{ data }">
              <input
              v-if="getOrder.stateLast === 1"
              v-model="data.requestQuantity"
              type="number"
              class="form-control"
              >
              <span v-else>{{data.requestQuantity}}</span>
            </template>
          </Column>
          <Column
          v-if="getOrder.stateLast >= 3"
          header="Cantidad Recibida"
          sortable
          field="receivedQuantity"
          headerStyle="width: 150px;"
          >
            <template #body="{data}">
              <div v-if="getOrder.stateLast === 3">
                <div class="p-inputgroup" >
                  <input v-model="data.receivedQuantity" type="number" class="form-control" >
                </div>
                <small v-if="!data.receivedQuantity" class="p-error">
                  El campo es requerido
                </small>
              </div>
              <span v-else>
                {{data.receivedQuantity}}
              </span>
            </template>
          </Column>
          <Column header="Solicitado Por"
          field="userRequest.full_name"
          sortable
          headerStyle="width: 150px;" />
          <Column v-if="getOrder.stateLast >= 4"
          header="Transferido Por"
          field="userTransfer.full_name"
          sortable
          headerStyle="width: 150px;" />
        </DataTable>
      </div>
    </div>
  </section>
  <Dialog v-model:visible="dialogAnularPedido" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3 text-red-600" style="font-size: 2rem" />
      <span>
        ¿Está seguro de anular el pedido # consecutivo <b>{{getOrder.consecutive}}</b>?
      </span>
      <div class="w-full mt-3">
        <label for="justification">Justificación</label>
        <div class="p-inputgroup">
          <textarea id="justification" rows="6" class="form-control" v-model="model.justification"></textarea>
        </div>
        <messageError :text="errors.justification" />
      </div>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text p-button-danger" @click="dialogAnularPedido = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text p-button-success" @click="onSubmitAnular()" />
    </template>
  </Dialog>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import pedidoBuscarService from '../../../../services/pedidoBuscarService'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { messageConfirm, messageSuccess } from '../../../../../../../libs/mensajes'
import pedidoConsolidarService from '../../../../services/pedidoConsolidarService'
import pedidoTransferirService from '../../../../services/pedidoTransferirService'
import pedidoGuardarService from '../../../../services/pedidoGuardarService'
import pedidoEstadoActualizarService from '../../../../services/pedidoEstadoActualizarService'
import { useForm, useField } from 'vee-validate'
import { object, string } from 'yup'

export default {
  name: 'Details',
  components: {
    DataTable,
    Column
  },
  setup () {
    const route = useRoute()
    const validationSchema = object().shape({
      justification: string().required().nullable().label('justificación')
    })
    const { values: model, handleSubmit, handleReset, errors } = useForm({
      validationSchema
    })
    useField('justification', null, { initialValue: null })

    const dialogAnularPedido = ref(false)

    const order = ref({
      id: null,
      stateLast: 0,
      consecutive: 0,
      details: []
    })

    const getOrder = computed(() => {
      return order.value
    })

    const getDetails = computed(() => {
      return order.value.details
    })

    const setOrder = (value) => {
      order.value = value
      if (value.stateLast === 3) {
        for (const key in value.details) {
          order.value.details[key].receivedQuantity = value.details[key].requestQuantity
        }
      }
    }
    const fetchOrder = () => {
      const { id } = route.params
      return pedidoBuscarService(id).then(({ data }) => {
        setOrder(data)
      }).catch(err => {
        console.error(err.message)
      })
    }
    const onSubmitAnular = handleSubmit((values) => {
      dialogAnularPedido.value = false
      onSubmit(5)
    })
    const onSaveDetails = async () => {
      const { id } = route.params
      const payload = {
        correspondenceId: getOrder.value.correspondenceId,
        details: getDetails.value
      }
      return await pedidoGuardarService(id, payload).then(({ data }) => {
        return { data }
      }).catch(err => {
        console.error(err.message)
        return []
      })
    }
    const onSubmit = (stateCode = 0) => {
      if (stateCode === 0) return false
      messageConfirm('no es posible deshacer esta accion').then(({ isConfirmed }) => {
        if (!isConfirmed) return false
        const { id } = route.params

        if (stateCode === 1) { // guardando el pedido
          onSaveDetails().then(data => {
            // console.log(data)
            messageSuccess('pedido guardadado')
          }).catch(err => {
            console.error(err.message)
          })
        } else if (stateCode === 2) { // solicitar a proveedor
          pedidoEstadoActualizarService(id, { state: 2, justification: 'solicitad a proveedor' }).then(() => {
            onSaveDetails().then(({ data }) => {
              messageSuccess('El estado del pedido ha sido actualizado')
              fetchOrder()
            })
          }).catch(err => {
            console.error(err.message)
          })
        } else if (stateCode === 3) { // confirmar recibido de proveedor
          onSaveDetails().then(({ data }) => {
            pedidoConsolidarService(id, getDetails.value).then(() => {
              pedidoEstadoActualizarService(id, { state: 3, justification: 'Se recibe el pedido del proveedor' }).then(() => {
                messageSuccess('pedido recibido')
                fetchOrder()
              }).catch(err => {
                console.error(err.message)
              })
            }).catch(err => {
              console.error(err.message)
            })
          })
        } else if (stateCode === 4) { // transferir a inventario
          onSaveDetails().then(({ data }) => {
            pedidoTransferirService(id).then(() => {
              pedidoEstadoActualizarService(id, { state: 5, justification: 'transferido a inventario' }).then(() => {
                fetchOrder()
                messageSuccess('Transferido a inventario')
              }).catch(err => {
                console.error(err.message)
              })
            }).catch(err => {
              console.error(err.message)
            })
          })
        } else if (stateCode === 5) { // anular el pedido
          pedidoEstadoActualizarService(id, { state: 6, justification: model.justification }).then(result => {
            fetchOrder()
            handleReset()
            messageSuccess('Pedido anulado')
          }).catch(err => {
            console.error(err.message)
          })
        }
      })
    }
    onMounted(() => {
      fetchOrder()
    })
    return {
      model,
      errors,
      getOrder,
      getDetails,
      dialogAnularPedido,
      onSubmitAnular,
      onSubmit
    }
  }
}
</script>
